import React from "react";
import 'swiper/css/pagination';
import { useMediaQuery } from 'react-responsive';
import AnimateText from "../../../../utilities/AnimateText";

function FrontSecondHead() {
   const isMobile = useMediaQuery({ query: `(max-width: 1199px)` });

   return (
      <>
         <div className="col-xl-7 col-md-8">
            {isMobile
               && <div className="front_second_wrap__title">
                  <h2>
                     It’s an immersive beauty space with the goal of connecting you to yourbody in a restorative way
                  </h2>
               </div>
            }

            {!isMobile
               && <AnimateText
                  containerClass={'front_second_wrap__title'}
                  textClass={'.front_second_wrap__title h2'}
                  trigger={'.front_second_wrap__title'}
                  colorStart={'#252323'}
                  colorEnd={'#FFF'}
               >
                  <div className="icon">

                  </div>
                  <h2>
                     It’s an immersive beauty space with the goal of connecting you to yourbody in a restorative way
                  </h2>
               </AnimateText>
            }
         </div>
         <div className="offset-xl-7 col-xl-4 offset-md-6 col-md-6">
            <div className="front_second_wrap__text">
               <p>
                  Reforme is a wellness spa that offers non-invasive treatments to reduce your body of lymphatic swelling, replenish your hydration and vitamin levels, and detoxify.
               </p>
            </div>
         </div>
      </>
   );
}

export default FrontSecondHead;
