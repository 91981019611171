function FooterSocial() {
   return (
      <div className="item_social_list">
         <a href="https://www.facebook.com/profile.php?id=61561662214045" className="item_social_list_item" target="_blank" rel="nofollow noindex noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
               <g clipPath="url(#clip0_1620_3575)">
                  <path d="M27.8125 25.9554H23.125V40C28.0962 39.2087 32.5881 36.5607 35.7046 32.5842C38.821 28.6077 40.3329 23.5951 39.9385 18.5466C39.544 13.498 37.2724 8.78472 33.5767 5.34702C29.881 1.90932 25.033 0 20 0C14.967 0 10.119 1.90932 6.42332 5.34702C2.72763 8.78472 0.45596 13.498 0.0615436 18.5466C-0.332873 23.5951 1.17897 28.6077 4.29542 32.5842C7.41188 36.5607 11.9038 39.2087 16.875 40V25.9554H11.7708V20.086H16.875V15.3696C17.1528 9.15083 21.1806 6.77513 28.9583 8.24247V13.1686H26.25C24.2361 13.2384 23.1944 14.2865 23.125 16.3129V20.086H28.6979" fill="#848685" />
               </g>
               <defs>
                  <clipPath id="clip0_1620_3575">
                     <rect width="40" height="40" fill="white" />
                  </clipPath>
               </defs>
            </svg>
         </a>
         <a href="https://www.instagram.com/reforme.nyc?igsh=N3F0dWFsenhjMjAy" className="item_social_list_item" target="_blank" rel="nofollow noindex noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
               <g clipPath="url(#clip0_1620_3584)">
                  <path d="M25.9608 10.6416H14.0389C12.1361 10.6416 10.5879 12.1898 10.5879 14.0927V26.0145C10.5879 27.9174 12.1361 29.466 14.0389 29.466H25.9608C27.8637 29.466 29.4123 27.9178 29.4123 26.0145V14.0927C29.4123 12.1898 27.8641 10.6416 25.9608 10.6416ZM19.9999 26.2507C16.5831 26.2507 13.8032 23.4708 13.8032 20.0536C13.8032 16.6368 16.5831 13.8569 19.9999 13.8569C23.4171 13.8569 26.197 16.6368 26.197 20.0536C26.197 23.4703 23.4166 26.2507 19.9999 26.2507ZM26.3962 15.1381C25.5873 15.1381 24.9296 14.4804 24.9296 13.672C24.9296 12.8636 25.5873 12.2058 26.3962 12.2058C27.2046 12.2058 27.8623 12.8636 27.8623 13.672C27.8623 14.4804 27.2046 15.1381 26.3962 15.1381Z" fill="#848685" />
                  <path d="M20.0004 16.4756C18.028 16.4756 16.4219 18.0808 16.4219 20.0532C16.4219 22.0265 18.028 23.6322 20.0004 23.6322C21.9737 23.6322 23.5785 22.0265 23.5785 20.0532C23.5785 18.0812 21.9732 16.4756 20.0004 16.4756Z" fill="#848685" />
                  <path d="M20 0C8.95475 0 0 8.95475 0 20C0 31.0453 8.95475 40 20 40C31.0453 40 40 31.0453 40 20C40 8.95475 31.0453 0 20 0ZM32.0306 26.0148C32.0306 29.3621 29.3081 32.0845 25.9609 32.0845H14.0391C10.6923 32.0845 7.96943 29.3621 7.96943 26.0148V14.093C7.96943 10.7462 10.6923 8.02335 14.0391 8.02335H25.9609C29.3081 8.02335 32.0306 10.7462 32.0306 14.093V26.0148Z" fill="#848685" />
               </g>
               <defs>
                  <clipPath id="clip0_1620_3584">
                     <rect width="40" height="40" fill="white" />
                  </clipPath>
               </defs>
            </svg>
         </a>
         <a href="tel:+16468613426" className="item_social_list_item" target="_blank" rel="nofollow noindex noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
               <g clipPath="url(#clip0_1620_3593)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 19.9995C0 8.97107 8.96636 0 19.9897 0L19.9864 0.000994949H19.998C31.0211 0.000994949 39.9878 8.9724 39.9878 20.0005C39.9878 31.0286 31.0214 40 19.998 40C15.9318 40 12.159 38.7891 8.99821 36.6963L1.31574 39.1535L3.80757 31.7221C1.41026 28.4297 0 24.373 0 19.9995ZM13.1373 9.1588C13.7249 9.19632 14.0312 9.23009 14.4062 10.1594L14.4087 10.1444C14.5306 10.4389 14.6969 10.8429 14.8797 11.2868C15.4188 12.596 16.1009 14.2523 16.2064 14.4718C16.3476 14.7658 16.4889 15.1785 16.2889 15.5537C16.1119 15.9303 15.956 16.1097 15.6909 16.4147C15.6752 16.4329 15.6591 16.4514 15.6426 16.4705C15.519 16.613 15.3978 16.741 15.2771 16.8685C15.1097 17.0454 14.9432 17.2213 14.7725 17.4335C14.7675 17.4394 14.7626 17.4452 14.7576 17.451C14.4914 17.7638 14.2025 18.1034 14.5375 18.6805C14.8787 19.2558 16.0538 21.1744 17.7815 22.7128C19.8298 24.537 21.5231 25.2221 22.2871 25.5312C22.3554 25.5589 22.4163 25.5835 22.4694 25.6057C22.9507 25.8058 23.527 25.7558 23.8795 25.3806C24.2686 24.9618 24.7395 24.2995 25.2267 23.6144C25.2982 23.5138 25.3701 23.4128 25.4421 23.3119C25.8422 22.7478 26.3472 22.6765 26.876 22.8766C27.4173 23.0655 30.2725 24.4638 30.8601 24.7702C31.0105 24.8487 31.1477 24.9152 31.271 24.975C31.6294 25.1489 31.8704 25.2657 31.9764 25.4518C32.1189 25.702 32.1189 26.8652 31.6364 28.2284C31.1538 29.5917 29.2387 30.7211 27.7111 31.05C26.6647 31.2739 25.3021 31.4503 20.7067 29.5454C15.3106 27.3106 11.6788 22.0809 10.8935 20.95C10.8235 20.8493 10.7762 20.7811 10.7522 20.7504C10.7447 20.7402 10.736 20.7284 10.7262 20.7149C10.3601 20.2158 8.37695 17.5121 8.37695 14.7182C8.37695 11.9315 9.75205 10.5323 10.3694 9.90416C10.3877 9.88559 10.4052 9.86769 10.4221 9.85045C10.9047 9.35892 11.7047 9.13379 12.4673 9.13379L13.1373 9.1588Z" fill="#848685" />
               </g>
               <defs>
                  <clipPath id="clip0_1620_3593">
                     <rect width="40" height="40" fill="white" />
                  </clipPath>
               </defs>
            </svg>
         </a>
      </div>
   );
}

export default FooterSocial;