import React from "react";
import ContactsHead from "../components/Blocks/Contacts/ContactsHead";

function Contacts() {

   return (
      <>
         <ContactsHead />
      </>
   )
}

export default Contacts