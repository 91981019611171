import React from "react";
import './Seriveces.scss';
import { useMediaQuery } from 'react-responsive';
import SerivecesDesktop from "./SerivecesDesktop.jsx";
import SerivecesMobile from "./SerivecesMobile.jsx";


function Seriveces() {
   const isDesktop = useMediaQuery({ query: `(min-width: 1200px)` });

   const list = [
      {
         title: 'Endospheres',
         text: 'Endospheres Compressive Micro Vibration is a non-invasive treatment that reduces cellulite, tightens skin, and tones muscle. Using low-frequency vibrations that pulse deep into muscle tissue, Endospheres therapy prompts lymphatic drainage, flushes toxins, and reduces fluid retention.',
         url: '/endospheres',
         image: {
            link: './img/FrontPage/endospheres.jpg',
            alt: ''
         },
         key: '1111',
         meta: {
            duration: '30-60 min',
            cost: '$250'
         },
         people: {
            avatar: './img/FrontPage/people.jpg',
            alt: '',
            name: 'Name of a cosmetologist',
            link: '#',
         }
      },
      {
         title: 'Lymphatic Compression',
         text: 'The Lymphatic Compression treatments at Reforme use BallancerPro compression therapy to treat and drain the lymphatic system. Through scientific research, Lymphatic Compression treatments use the same principles of a manual lymphatic massage, but with more effective results.',
         url: '/lymphatic-compression',
         image: {
            link: './img/FrontPage/expo2.jpg',
            alt: ''
         },
         key: '1112',
         meta: {
            duration: '45-60 min',
            cost: '$250'
         },
         people: {
            avatar: './img/FrontPage/people.jpg',
            alt: '',
            name: 'Name of a cosmetologist',
            link: '#',
         }
      },
      {
         title: 'Iyashi Dôme Japanese Sauna',
         text: 'Iyashi means wellbeing in Japanese. The Iyashi Dôme is a type of Japanese sauna that uses far infrared rays to heat up the body directly. ',
         url: '/iyashi-dôme-japanese-sauna',
         image: {
            link: './img/FrontPage/expo3.jpg',
            alt: ''
         },
         key: '1113',
         meta: {
            duration: '30 min',
            cost: '$250'
         },
         people: {
            avatar: './img/FrontPage/people.jpg',
            alt: '',
            name: 'Name of a cosmetologist',
            link: '#',
         }
      },
      {
         title: 'Hyperbaric Oxygen Chamber',
         text: 'Hyperbaric Oxygen Therapy delivers pure oxygen in an environment where the air is 2-3 times more pressurized than normal. This allows the lungs to safely absorb more oxygen than it normally could.',
         url: '/hyperbaric-oxygen-chamber',
         image: {
            link: './img/FrontPage/hyperbaric-oxygen-therapy.jpg',
            alt: ''
         },
         key: '1114',
         meta: {
            duration: '60-90 min',
            cost: '$100'
         },
         people: {
            avatar: './img/FrontPage/people.jpg',
            alt: '',
            name: 'Name of a cosmetologist',
            link: '#',
         }
      },
      {
         title: 'Red Light Therapy & LED Collagen Bed',
         text: 'The Red Light Therapy & LED Collagen Bed uses low-level wavelengths to minimize the appearance of wrinkles and fine lines.',
         url: '/red-light-therapy-led-collagen-bed',
         image: {
            link: './img/FrontPage/red-light-therapy.jpg',
            alt: ''
         },
         key: '1115',
         meta: {
            duration: '40 min',
            cost: '$100'
         },
         people: {
            avatar: './img/FrontPage/people.jpg',
            alt: '',
            name: 'Name of a cosmetologist',
            link: '#',
         }
      },
      {
         title: 'IV Therapy',
         text: 'IV Therapy offers a combination of hydration and mixtures of much-needed supplements through an intravenous treatment. ',
         url: '/iv-therapy',
         image: {
            link: './img/FrontPage/iv-therapy.jpg',
            alt: ''
         },
         key: '1116',
         meta: {
            duration: '30 min',
            cost: '$100'
         },
         people: {
            avatar: './img/FrontPage/people.jpg',
            alt: '',
            name: 'Name of a cosmetologist',
            link: '#',
         }
      }
   ]

   return (
      <section className="explore" id="services">
         <div className="container">
            <div className="explore__wrap row">
               {isDesktop ? (
                  <SerivecesDesktop list={list} />
               ) : (
                  <>
                     <div className="col-12 col_head">
                        <div className="title">
                           Explore our Services
                        </div>
                        <div className="text">
                           <p>
                              Take a journey with us through the many services that Reforme offers so that you can align your body, mind, and spiritual outlooks
                           </p>
                        </div>
                     </div>
                     <SerivecesMobile list={list} />
                  </>
               )}
            </div>
         </div>
      </section>
   );
}

export default Seriveces;