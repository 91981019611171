import React, { useState, useEffect } from "react";
import './FrontDiscover.scss';
import { useMediaQuery } from 'react-responsive';
import SwiperDiscover from "./SwiperDiscover";
import { v4 as uuidv4 } from 'uuid';
import { gsap, TweenMax } from 'gsap';
import BtnBg from "../../../Utilities/BtnBg/BtnBg";
import { useModal } from '../../../../utilities/ModalContext';
import ModalReserve from "../../Modal/ModalType/ModalReserve";


function FrontDiscover() {
   const { openModal } = useModal();
   const [showSlider, setShowSlider] = useState(false);
   const isMobile = useMediaQuery({ query: `(max-width: 1199px)` });

   useEffect(() => {
      if (isMobile) {
         setShowSlider(true);
      } else {
         setShowSlider(false);
      }
   }, [isMobile]);

   const list = [
      { title: 'Inclusive Well-Being', text: 'At Reforme, our belief is that well-being knows no bounds. We are committed to providing inclusive services that cater to diverse backgrounds, preferences, and health goals. Each individual is celebrated for their uniqueness, recognizing that the journey to wellness is a personal and universal pursuit.' },
      { title: 'Personalized Support', text: 'Your journey with Reforme is marked by personalized care. Our dedicated team takes the time to understand your unique needs, ensuring that our services are tailored to your specific requirements. This commitment to personalized support ensures that every visit is an opportunity for you to experience wellness on your terms.' },
      { title: 'Accessible Innovation', text: 'Embracing progress in wellness is at the core of Reforme. We integrate accessible innovations and practical treatments to make well-being achievable for all. From mindful spa experiences to fitness programs accommodating various fitness levels, we provide effective, attainable solutions.' },
      { title: 'Education for Empowerment', text: 'Empowering you with knowledge is integral to the Reforme philosophy. Our aim is to provide education that extends beyond our center, offering information on nutrition, fitness, and stress management. We seek to empower you to make informed choices that contribute to your overall well-being.' },
      { title: 'Tranquil Haven in the Urban Landscape', text: 'Amidst the vibrancy of New York City, Reforme Wellness Center stands as a peaceful haven—a sanctuary where anyone can escape the city\'s demands and find moments of tranquility, introspection, and renewal. The calming environment and thoughtful design invite individuals from all walks of life to prioritize their well-being.' },
      { title: 'Commitment to Quality', text: 'Quality is paramount at Reforme Wellness Center. From the expertise of our team to the sincerity of our services, we are committed to providing a high standard of care. Your journey with us is about feeling your best, and we strive to deliver an experience that reflects our unwavering commitment to excellence.' }
   ];

   const groupList = (items, groupSize) => {
      const groups = [];
      for (let i = 0; i < items.length; i += groupSize) {
         groups.push(items.slice(i, i + groupSize));
      }
      return groups;
   };

   const groupedList = groupList(list, 3);

   const handleHover = (e) => {
      const item = e.currentTarget;
      const rect = item.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
      const thisCircle = item.querySelector('.item__circle');
      thisCircle.style.left = mouseX + 'px';
      thisCircle.style.top = mouseY + 'px';
   };

   const handleMouseEnter = (e) => {
      const textElement = e.currentTarget.querySelector('.item__text');
      TweenMax.killTweensOf(textElement);
      gsap.fromTo(textElement,
         {
            height: 0,
            opacity: 0
         },
         {
            height: 'auto',
            opacity: 1,
            duration: .35,
            delay: .55,
            ease: 'none'
         });
   };

   const handleMouseLeave = (e) => {
      const textElement = e.currentTarget.querySelector('.item__text');
      TweenMax.killTweensOf(textElement);
      gsap.fromTo(textElement, {
         height: 'auto',
         opacity: 1
      }, {
         height: 0,
         opacity: 0,
         duration: .4,
         delay: 0,
         ease: 'none'
      });
   };

   return (
      <section className="discover">
         <div className="container">
            <div className="discover__head">
               <h2>Discover Reforme <br /> Wellness Center</h2>
               <BtnBg
                  colorBtn='green'
                  hoverColor='white'
                  onClick={() => openModal('Reserve your visit', <ModalReserve />)}
               >
                  Reserve your visit
               </BtnBg>
            </div>
            <div className="discover__wrap row">
               {showSlider ? (
                  <SwiperDiscover props={list} />
               ) : (
                  groupedList.map(group => (
                     <div className="wrap_item" key={uuidv4()}>
                        {group.map(item => (
                           <div
                              className="item"
                              onMouseMove={handleHover}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              key={uuidv4()}
                           >
                              <div className="item__title">
                                 <h3>{item.title}</h3>
                              </div>
                              <div className="item__text">
                                 <p>{item.text}</p>
                              </div>
                              <div className="item__circle"></div>
                           </div>
                        ))}
                     </div>
                  ))
               )}
            </div>
         </div>
      </section>
   );
}

export default FrontDiscover;
