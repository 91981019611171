import React, { useState, useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import './AnimatedRoutes.scss'

const AnimatedRoutes = ({ children }) => {
   const location = useLocation();
   const [displayLocation, setDisplayLocation] = useState(location);
   const [transitionStage, setTransistionStage] = useState("fadeIn");

   useEffect(() => {
      if (location !== displayLocation) {

         setTransistionStage("fadeOut")
      };

   }, [location, displayLocation]);

   useEffect(() => {
      // Прокрутка страницы наверх при изменении displayLocation
      window.scroll(0, 0);
   }, [displayLocation]);

   return (
      <>
         <div className={`animate_page ${transitionStage}`}
            onAnimationEnd={() => {
               if (transitionStage === "fadeOut") {
                  setTransistionStage("fadeIn");
                  setDisplayLocation(location);
               }
               document.querySelector('html').style.scrollBehavior = 'auto';
            }}>
            <svg width="500" height="73" viewBox="0 0 500 73" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M34.0039 65.125L21.142 42.9622H7.61777V65.125H0V7.23145H22.9361C27.849 7.23145 32.0304 8.97269 35.5495 12.5249C39.0685 15.9935 40.7936 20.2143 40.7936 25.1734C40.7936 28.8928 39.6482 32.3753 37.4264 35.426C35.2183 38.4906 32.4306 40.7194 28.9116 41.9592L42.4358 65.1111H34.0039V65.125ZM7.61777 14.4193V32.1664C7.61777 34.2838 9.31522 36.011 11.4267 36.011H22.9498C25.8755 36.011 28.2905 34.8967 30.2778 32.7375C32.1132 30.7594 33.1483 28.0849 33.1897 25.3685C33.2311 22.3039 32.2513 19.671 30.1536 17.5536C28.194 15.478 25.8065 14.4055 22.9498 14.4055H7.61777V14.4193Z" fill="white" />
               <path d="M77.3639 57.8389H103.998V65.1104H69.7461V7.2168H103.584V14.4884H77.3639V32.2771H101.542V39.479H81.4625C79.1993 39.479 77.3639 41.3317 77.3639 43.6162V57.8389Z" fill="white" />
               <path d="M438.235 57.8389H464.869V65.1104H430.617V7.2168H464.455V14.4884H438.235V32.2771H462.413V39.479H442.334C440.07 39.479 438.235 41.3317 438.235 43.6162V57.8389Z" fill="white" />
               <path d="M165.866 14.5022H140.459V33.2801H164.803V40.5516H144.448C142.253 40.5516 140.473 42.3485 140.473 44.5634V65.1104H132.855V7.2168H165.879V14.5022H165.866Z" fill="white" />
               <path d="M309.254 65.125L296.392 42.9622H282.868V65.125H275.25V7.23145H298.186C303.099 7.23145 307.28 8.97269 310.799 12.5249C314.319 15.9935 316.044 20.2143 316.044 25.1734C316.044 28.8928 314.898 32.3753 312.69 35.426C310.482 38.4906 307.694 40.7194 304.162 41.9592L317.686 65.1111H309.254V65.125ZM282.868 14.4193V32.1664C282.868 34.2838 284.565 36.011 286.677 36.011H298.2C301.125 36.011 303.541 34.8967 305.528 32.7375C307.363 30.7594 308.398 28.0849 308.44 25.3685C308.481 22.3039 307.501 19.671 305.404 17.5676C303.444 15.492 301.056 14.4193 298.2 14.4193H282.868Z" fill="white" />
               <path d="M398.65 7.2168V65.1104H391.115V19.8654L372.346 51.3753H371.367L352.598 19.9489V65.1104H344.98V7.2168H353.578L368.855 32.8064C370.207 35.077 373.492 35.077 374.844 32.8064L390.052 7.23074H398.65V7.2168Z" fill="white" />
               <path d="M486.234 7.2168V9.49896H482.212V21.7097H479.833V9.49896H475.781V7.2168H486.234Z" fill="white" />
               <path d="M499.997 7.2168V21.7097H497.619V11.3958L493.244 18.6866H492.979L488.605 11.3661V21.7097H486.227V7.2168H488.781L493.126 14.4781L497.472 7.2168H499.997Z" fill="white" />
               <path d="M245.739 10.8108C238.991 3.92934 230.366 0.405043 219.85 0.391113C209.348 0.405043 200.736 3.92934 193.988 10.8108C187.24 17.7062 183.941 26.2035 183.941 36.6233C183.941 47.043 187.24 55.5404 193.988 62.4358C200.736 69.3173 209.361 72.8416 219.863 72.8555C230.365 72.8416 238.977 69.3173 245.725 62.4358C252.474 55.5404 255.786 47.043 255.786 36.6233C255.786 26.2035 252.487 17.7062 245.739 10.8108ZM240.246 56.7384C234.933 62.1015 228.144 64.8457 219.877 64.8596C211.597 64.8457 204.807 62.1015 199.481 56.7384C194.154 51.3613 191.559 44.7445 191.559 36.6233C191.559 28.502 194.154 21.8852 199.481 16.5082C204.794 11.1451 211.583 8.40092 219.85 8.38699C228.13 8.40092 234.92 11.1451 240.246 16.5082C245.56 21.8852 248.168 28.502 248.168 36.6233C248.168 44.7585 245.573 51.3753 240.246 56.7384Z" fill="#56DAB0" />
            </svg>
         </div>
         <Routes location={displayLocation}>{children}</Routes>
      </>
   );
};

export default AnimatedRoutes;
